import React, {useState} from "react";
import BlogFooter from "../pages/BlogFooter";
import Image1 from "../images/jpeg/Code1.JPG";
import Logo from "../images/svg/Logo.svg";
import VideoPlayer from "../video/VideoPlayer";
import "../styles/Rust.css";
import Links from "../pages/Links";
import BodyOne from "../writing-component/rust/BodyOne";
import Header from "../pages/Header";
import {Link} from "react-router-dom";
import Footer from "../pages/Footer";


const Rust = () => {

    const [selectedItem, setSelectedItem] = useState("About");

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <div className="mt-32 ml-40 mb-10 sm:ml-10 sm:overflow-x-hidden">
            <Header />
            {/* */}
            <div className="flex flex-row custom:flex-col mt-20
      custom:-ml-40 custom:justify-center custom2:flex-col custom2:-ml-40 custom2:justify-center
      custom3:flex-col custom3:-ml-40 custom3:justify-center custom3:mt-10
      custom2:mt-10">
                <div className="sm:ml-5 mb-2 custom:flex custom:flex-row
        custom2:flex custom2:flex-row custom3:flex custom3:flex-row">
                    <ul className="flex flex-col w-full h-full custom:h-12 custom:pt-2 custom:border custom:mr-7 custom:ml-7 custom:flex
          custom:flex-row custom:space-x-24 custom:justify-center
          custom2:h-10 custom2:pt-2 custom2:border custom2:mr-7 custom2:ml-7 custom2:flex custom2:flex-row
          custom2:space-x-20 custom2:justify-center
          custom3:h-10 custom3:pt-2 custom3:border custom3:mr-7 custom3:ml-7 custom3:flex custom3:flex-row
          custom3:space-x-10 custom3:justify-center">
                        <li
                            onClick={() => handleItemClick("About")}
                            className={`cursor-pointer mb-2 text-md sm:text-md ${
                                selectedItem === "About" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "About" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/">About</Link>
                        </li>
                        <li
                            onClick={() => handleItemClick("Writing")}
                            className={`cursor-pointer mb-2 text-md ${
                                selectedItem === "Writing" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "Writing" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/writing">Writing</Link>
                        </li>
                        <li
                            onClick={() => handleItemClick("Misc")}
                            className={`cursor-pointer text-md ${
                                selectedItem === "Misc" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "Misc" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/misc">Misc</Link>
                        </li>
                    </ul>
                </div>


                <div className="sm:ml-14 sm:mr-2 sm:w-9/12 ml-40 text-[#acaeb8] text-md w-6/12 custom:text-sm custom:mt-5
        custom:w-9/12 custom:ml-20 custom2:text-md custom2:mt-5 custom2:w-10/12
        custom2:ml-10 custom3:mt-8 custom3:w-10/12 custom3:ml-8 custom3:mr-5
        custom3:text-md md:ml-32 mlg:w-8/12">

                    <img src="/src/images/jpeg/hero and shit.jpeg" width="225" height="225"/>

                    <h1>Intial Thoughts</h1>
                    <br/>
                    <br/>
                    <p> In December 2023, I came across some post about Rust’s memory management on Quora that got me
                        curious and fascinated.
                        I went over Rust's official documentation, and frankly, I was enamored
                    </p>
                    <br/>
                    <p> Modern language, modern features, systems level control. I decided this might be time to learn a
                        proper systems level language.

                        I have a faint high level understanding of how C works and I know for a fact that the pitfalls
                        are damn crazy. I decided to delve into learning Rust, a language that offers nearly the same
                        power as C without C’s pitfalls.

                        Although I didn’t fully understand the Ownership system at that time, I was still curious enough
                        to see what it would take to learn this language. Turns out, there is a comprehensive
                        hand-holding docs from the official team to help get started. </p>
                    <br/>
                    <p>Now, it is time.</p>


                </div>
            </div>

            <Footer />
        </div>
    );

};

export default Rust;
