import React, {useState} from "react";
import BlogFooter from "../pages/BlogFooter";
import Image1 from "../images/jpeg/Code1.JPG";
import Logo from "../images/svg/Logo.svg";
import VideoPlayer from "../video/VideoPlayer";
import "../styles/Rust.css";
import Links from "../pages/Links";
import BodyOne from "../writing-component/rust/BodyOne";
import Header from "../pages/Header";
import {Link} from "react-router-dom";
import Footer from "../pages/Footer";


const SkinnyPete = () => {

    const [selectedItem, setSelectedItem] = useState("About");

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <div className="mt-32 ml-40 mb-10 sm:ml-10 sm:overflow-x-hidden">
            <Header />
            {/* */}
            <div className="flex flex-row custom:flex-col mt-20
      custom:-ml-40 custom:justify-center custom2:flex-col custom2:-ml-40 custom2:justify-center
      custom3:flex-col custom3:-ml-40 custom3:justify-center custom3:mt-10
      custom2:mt-10">
                <div className="sm:ml-5 mb-2 custom:flex custom:flex-row
        custom2:flex custom2:flex-row custom3:flex custom3:flex-row">
                    <ul className="flex flex-col w-full h-full custom:h-12 custom:pt-2 custom:border custom:mr-7 custom:ml-7 custom:flex
          custom:flex-row custom:space-x-24 custom:justify-center
          custom2:h-10 custom2:pt-2 custom2:border custom2:mr-7 custom2:ml-7 custom2:flex custom2:flex-row
          custom2:space-x-20 custom2:justify-center
          custom3:h-10 custom3:pt-2 custom3:border custom3:mr-7 custom3:ml-7 custom3:flex custom3:flex-row
          custom3:space-x-10 custom3:justify-center">
                        <li
                            onClick={() => handleItemClick("About")}
                            className={`cursor-pointer mb-2 text-md sm:text-md ${
                                selectedItem === "About" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "About" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/">About</Link>
                        </li>
                        <li
                            onClick={() => handleItemClick("Writing")}
                            className={`cursor-pointer mb-2 text-md ${
                                selectedItem === "Writing" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "Writing" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/writing">Writing</Link>
                        </li>
                        <li
                            onClick={() => handleItemClick("Misc")}
                            className={`cursor-pointer text-md ${
                                selectedItem === "Misc" ? "text-[#22c55e]" : "text-white"
                            }`}
                        >
              <span
                  className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                      selectedItem === "Misc" ? "visible" : "invisible"
                  }`}
              ></span>
                            <Link to="/misc">Misc</Link>
                        </li>
                    </ul>
                </div>


                <div className="sm:ml-14 sm:mr-2 sm:w-9/12 ml-40 text-[#acaeb8] text-md w-6/12 custom:text-sm custom:mt-5
        custom:w-9/12 custom:ml-20 custom2:text-md custom2:mt-5 custom2:w-10/12
        custom2:ml-10 custom3:mt-8 custom3:w-10/12 custom3:ml-8 custom3:mr-5
        custom3:text-md md:ml-32 mlg:w-8/12">

                    <p>

                        If you’ve seen the Breaking Bad, then you will know Skinny Pete as one of Jesse’s long time goofy friend. Better recognized as an half of the Skinny Pete - Badger pair, I feel there is a lot to unpack from his character.

                        In the first few scenes of El-camino, the BB after-story, Jesse has just become a massive fugitive, the subject of a state-wide manhunt. Out of options, he found his way to the doors of Skinny Pete and Badger looking completely dishivelled. Badger answered the door first, and I could swear I saw a tinge of reticence and uneasiness around him the moment he recognized it was Jesse. Skinny Pete, on the other hand, was completely open-armed.

                        Skinny Pete and Badger cleaned Jesse up, but Ofcouse, Jesse couldn’t stay with them for long, so and the boys hastily planned his escape out of town. It came to the point where the duo had to raise him some cash, and again, you could notice the holding-out from Badger. At the point where it remained just Skinny Pete and Jesse by the car as the latter was bailing point, Pinkman asked him - “Why are you doing all these?”

                        “Dude… You’re my hero and shit” Skinny replied. For me, that was one of the most wholesome moment of the entire show.
</p>

                    <br/>


                <p>        At that point, we could see friendship in its truest form.  Skinny Pete was really that guy. Unhinged friendship really is one of the most beautiful thing, and it speaks volume to the person who Skinny Pete really was. Having a ride or die, someone you know you really can fall back on when ALL chips are really down, that’s really something. I wish everyone  get to have this sometime in their lifetime.

                        True friendship is where peace really resides, and I hold the opinion that the success of any other from of relationship - especially romantic ones, depends heavily on the kind of friendship that already exist.

                        “You’re my hero and shit…” This is a guy admitting to being fasinated at the kind of complex and intricate lifestyle his friend is leading. He is content to just watch, to be inspired. Lessons there really. Hopefully, we can all try to look not much further from our friends to be find inspiration, find push, draw strength.

                        Shout out to Skinny, shout out to good friends.
                    </p>
                    <br/>
                    <p>Now, it is time.</p>


                </div>
            </div>

            <Footer />
        </div>
    );

};

export default SkinnyPete;
