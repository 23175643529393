import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../pages/Header";
import Footer from "./Footer";

const HomePage = () => {

  const [selectedItem, setSelectedItem] = useState("About");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };


  return (
    <div className="mt-32 ml-40 mb-10 sm:ml-10 sm:overflow-x-hidden">
      <Header />
      {/* */}
      <div className="flex flex-row custom:flex-col mt-20
      custom:-ml-40 custom:justify-center custom2:flex-col custom2:-ml-40 custom2:justify-center
      custom3:flex-col custom3:-ml-40 custom3:justify-center custom3:mt-10
      custom2:mt-10">
        <div className="sm:ml-5 mb-2 custom:flex custom:flex-row
        custom2:flex custom2:flex-row custom3:flex custom3:flex-row">
          <ul className="flex flex-col w-full h-full custom:h-12 custom:pt-2 custom:border custom:mr-7 custom:ml-7 custom:flex
          custom:flex-row custom:space-x-24 custom:justify-center
          custom2:h-10 custom2:pt-2 custom2:border custom2:mr-7 custom2:ml-7 custom2:flex custom2:flex-row
          custom2:space-x-20 custom2:justify-center
          custom3:h-10 custom3:pt-2 custom3:border custom3:mr-7 custom3:ml-7 custom3:flex custom3:flex-row
          custom3:space-x-10 custom3:justify-center">
            <li
              onClick={() => handleItemClick("About")}
              className={`cursor-pointer mb-2 text-md sm:text-md ${
                selectedItem === "About" ? "text-[#22c55e]" : "text-white"
              }`}
            >
              <span
                className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                  selectedItem === "About" ? "visible" : "invisible"
                }`}
              ></span>
              <Link to="/">About</Link>
            </li>
            <li
              onClick={() => handleItemClick("Writing")}
              className={`cursor-pointer mb-2 text-md ${
                selectedItem === "Writing" ? "text-[#22c55e]" : "text-white"
              }`}
            >
              <span
                className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                  selectedItem === "Writing" ? "visible" : "invisible"
                }`}
              ></span>
              <Link to="/writing">Writing</Link>
            </li>
            <li
              onClick={() => handleItemClick("Misc")}
              className={`cursor-pointer text-md ${
                selectedItem === "Misc" ? "text-[#22c55e]" : "text-white"
              }`}
            >
              <span
                className={`inline-block w-2 h-2 bg-[#22c55e] rounded-full mr-2 ${
                  selectedItem === "Misc" ? "visible" : "invisible"
                }`}
              ></span>
              <Link to="/misc">Misc</Link>
            </li>
          </ul>
        </div>


        <div className="sm:ml-14 sm:mr-2 sm:w-9/12 ml-40 text-[#acaeb8] text-md w-6/12 custom:text-sm custom:mt-5
        custom:w-9/12 custom:ml-20 custom2:text-md custom2:mt-5 custom2:w-10/12
        custom2:ml-10 custom3:mt-8 custom3:w-10/12 custom3:ml-8 custom3:mr-5
        custom3:text-md md:ml-32 mlg:w-8/12">
          <p> I am a software Engineer. Specifically, I am a backend/Devops/Infrastructure engineer.
            <br/>
            Backend engineer because I am curious about the code that makes things happen. Infrastructure engineer
            because I am curious about
            the machines that makes things happen.
            <br/>
            In the grand scheme of things, I am continually curious about computing. </p>
          <br/>
          <p>Prior to be a software engineer, I was a Barrister at Law. I hope to get to be many other things. </p>
          <br/>
          <p> I read sometimes - favorite author is John Grisham. </p>
          <br/>
          <p> I watch movies sometimes - favorite movies are the Breaking Bad collection. </p>

        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
